<template>
  <div class="container-fluid mt-3">
    <form @submit="handleSubmitForm">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الصفحات' , path: '/pages'}" current_page="إضافة صفحة"/>
        <div class="controls-btns d-flex gap-2 mb-4">
          <button class="btn btn-publish" type="button" @click="handleSubmitForm" :disabled="isSubmitting">حفظ</button>
        </div>
      </div>
      <page-form/>
    </form>
  </div>
</template>

<script setup>
import Breadcrumb from "@/components/Breadcrumb";
import PageForm from './component/PageForm'
import {useForm} from "vee-validate";
import {createPage} from "@/services/pages";
import {useToast} from 'vue-toastification';
import {useRouter} from 'vue-router';

const toast = useToast();
const router = useRouter();

const schema = {
  name: 'required',
  description: 'required',
  content: 'required'
};

const {handleSubmit,isSubmitting } = useForm({validationSchema:schema})

const handleSubmitForm = handleSubmit( async (values,{ resetForm }) =>{
  try {
    const result = await createPage(values)
    toast.success('تم الحفظ بنجاح')
    await router.push('/pages')
  }catch (e) {
    toast.error('حدث خطأ, يرجى المحاولة مرة أخرى')
    console.log(e)
  }
})
</script>
